﻿// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js
const myMSALObj = new msal.PublicClientApplication(msalConfig);

const LOGIN_PAGE = $("#MILoginPage").val();

let username = "";

selectAccount();

function selectAccount() {
  const currentAccounts = myMSALObj.getAllAccounts();

  if (currentAccounts.length === 0) {
    navigateToLoginPage();
  } else if (currentAccounts.length > 1) {
    // Add your account choosing logic here
    console.warn("Multiple accounts detected.");
  } else if (currentAccounts.length === 1) {
    username = currentAccounts[0].username;
  }
}

function navigateToLoginPage() {
  // save current route to sessionStorage to load it back when the user has logged in
  sessionStorage.setItem("currentRoute", window.location.href);
  window.location.href = LOGIN_PAGE;
}

function getTokenRedirect(request) {
  request.account = myMSALObj.getAccountByUsername(username);

  return myMSALObj.acquireTokenSilent(request).catch((error) => {
    console.warn(
      "silent token acquisition fails. acquiring token using redirect"
    );
    if (error instanceof msal.InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      return myMSALObj.acquireTokenRedirect(request);
    } else {
      console.warn(error);
      navigateToLoginPage();
    }
  });
}

/**
 * Helper function to signOut, in case of stuck accounts.
 * As this function is in global namespace, it can be called from the brower's console directly.
 */
function signOut() {
  const logoutRequest = {
    account: myMSALObj.getAccountByUsername(username),
  };

  myMSALObj.logout(logoutRequest);
}
